<template lang="">
  <div>
    <!-- Start Filter -->
    <b-card v-if="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="8">
          <form-v-select
            ref="region"
            v-model="region"
            dataurl="region"
            col-label="3"
            label="Region"
            placeholder="Semua Region"
            :item_text="'name'"
          />
        </b-col>
        <b-col cols="8">
          <form-v-select
            ref="vendor"
            v-model="vendor"
            dataurl="vendor"
            col-label="3"
            label="Vendor"
            placeholder="Semua Vendor"
            :item_text="'name'"
          />
        </b-col>
        <b-col cols="8">
          <b-form-group
            label-cols-md="3"
            label="Tanggal"
          >
            <b-input-group>
              <flat-pickr
                v-model="date_start"
                class="form-control"
                placeholder="Tanggal Awal"
              />
              <b-input-group-append is-text>
                To
              </b-input-group-append>
              <flat-pickr
                v-model="date_end"
                class="form-control"
                placeholder="Tanggal Akhir"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label-cols-md="3"
            label="Status"
          >
            <b-form-select
              v-model="status"
              :options="[
                { value: null, text: 'Semua' },
                { value: 'created', text: 'Dibuat' },
                { value: 'submitted', text: 'Diajukan' },
                { value: 'approved', text: 'Disetujui' },
                { value: 'paid', text: 'Selesai' },
                { value: 'rejected', text: 'Ditolak' },
              ]"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label=""
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="primary"
              type="button"
              @click="show"
            >Show</b-button>
            <b-button
              class="mr-1"
              size="sm"
              variant="outline-secondary"
              type="button"
              @click="reset"
            >Reset</b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- End Of Filter -->
    <b-card>
      <basetable
        ref="basetable"
        :dataurl="dataurl+'?filter[billingable_type]=App\\Model\\Spb\\Spb'+(userType == 'vendor'?'&filter[spbs.vendor_id]='+user.id:'')"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :filter="filter"
        :is-edit="false"
        :is-delete="false"
        :is-add="false"
      >
        <template v-slot:buttonaddon>
          <b-button
            class="mr-1"
            size="sm"
            variant="success"
            @click="showFilter =!showFilter"
          >
            <feather-icon icon="FilterIcon" />
            Filter
          </b-button>
          <!-- <b-button
            class="mr-1"
            size="sm"
            variant="warning"
          >
            Export
          </b-button> -->
        </template>
        <template #cell(billingable.region.name)="data">
          <span>{{ data.item.region_id?data.item.region.name:data.item.billingable.region.name }}</span>
        </template>
        <template #cell(index)="data">
          {{getBillCount(data.item)}}
        </template>
        <template #cell(month_index)="data">
          {{getLastMonthBill(data.item)}}
        </template>
        <template #cell(total)="data">
          {{getTotalTagihan(data.item)}}
        </template>
        <template #cell(billed)="data">
          <span :class="'badge bg-'+fieldStatus[getStatus(data.item)].color">{{fieldStatus[getStatus(data.item)].value}}</span>
        </template>
        <template v-slot:buttonaddaction>
          <b-button 
            v-if="userType == 'vendor' || userType == 'pusat' || userType == 'staff'"
            v-b-modal="'modal-select'"
            class="ml-1"
            size="sm"
            variant="primary"
          >
            
            <feather-icon icon="PlusIcon" />
            Tambah
          </b-button>
        </template>
      </basetable>
      <b-modal
        :id="'modal-select'"
        centered
        title="Tambah Penagihan Sewa"
        hide-footer
        no-close-on-backdrop
      >
        <div>
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col cols="12">
                  <form-v-select
                    v-model="spb"
                    :selected="spb"
                    rules="required"
                    label="Nomor SPB"
                    item_text="number"
                    placeholder="Select Nomor SPB"
                    :options="optionSPB"
                  />
                </b-col>
                <b-col cols="12">
                  <hr>
                  <b-form-group
                    label-cols-md="3"
                    label=""
                  >
                    <b-button
                      style="float: right;"
                      class="mr-1"
                      size="sm"
                      variant="primary"
                      type="button"
                      @click.prevent="validationForm"
                    >
                      <span v-if="loading">
                        <b-spinner small />
                        Loading...
                      </span>
                      <span v-else>Tambah</span>
                    </b-button>
                    <b-button
                      style="float: right;"
                      class="mr-1"
                      size="sm"
                      variant="warning"
                      type="button"
                      @click.prevent="hideForm"
                    >
                      <span>Batal</span>
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </div>
      </b-modal>
    </b-card>
  </div>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import basetable from '@/views/base/BaseTable.vue'
import { BCard,BButton,BFormGroup,BFormSelect,BRow,BCol, BInputGroup, BInputGroupAppend, BModal, BSpinner, BForm } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import moment from 'moment'

export default {
  components: {
    ValidationObserver,
    basetable,
    BCard,
    BButton,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol,
    flatPickr,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    FormVSelect,
    BForm,
    BSpinner
  },
  data() {
    return {
      userType:null,
      user:null,
      dataurl:"/billing",
      baseroute:"billing-rent",
      title:"Daftar Penagihan Sewa",
      fields: [
        { key: 'number', label: 'Nomor Penagihan', sortable: true },
        { key: 'billingable.number', label: 'Nomor SPB', sortable: true },
        { key: 'billingable.vendor.name', label: 'Vendor', sortable: true },
        { key: 'billingable.contract_subject', label: 'Judul Kontrak', sortable: true },
        { key: 'billingable.contract_number', label: 'Nomor Kontrak', sortable: true },
        { key: 'billingable.region.name', label: 'Region', sortable: true },
        { key: 'billingable.date', label: 'Tanggal Pengajuan', sortable: true },
        { key: 'index', label: 'Periode Penagihan', sortable: true },
        { key: 'month_index', label: 'Bulan Tagihan Terakhir', sortable: true },
        { key: 'total', label: 'Total Tagihan', sortable: true },
        { key: 'billed', label: 'Status', sortable: true },
      ],
      fieldStatus:{
        lunas:{color:'success', value:'Lunas'},
        proses:{color:'secondary', value:'On Progress'}
      },
      showFilter : false,
      loading:false,
      optionSPB:[],
      spb:null,
      filter :[],
      region:null,
      vendor:null,
      status:null,
      date_start:"",
      date_end:"",

    }
  },
  created(){
    if(this.$store.state.profile.info){
      var info = this.$store.state.profile.info
      this.userType = info.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$store.state.profile.info.contact
    }
    if(this.$route.meta.baseroute)
      this.baseroute = this.$route.meta.baseroute;
    this.fetchDataSPB()
  },
  mounted(){
  },
  methods:{
    show(){
      this.filter['filter[spbs.branch_id]'] = this.region?this.region.id:null
      this.filter['filter[spbs.vendor_id]'] = this.vendor?this.vendor.id:null
      this.filter['filter[billings.status]'] = this.status
      if(this.date_start != "" && this.date_end != "")
        this.filter['between[billings.date]']=this.date_start+","+this.date_end

      this.$refs.basetable.fetchData()
    },
    reset(){
      this.region = null
      this.vendor = null
      this.status = null
      this.date_start = ""
      this.date_end = ""
      this.filter['between[billings.date]'] = null
      this.show()
    },
    fetchDataSPB(){
      let url = ''
      const status = 'distributed,bapp_requested,bapp_approved,bapp_published'
      if(this.userType == 'vendor')
        url = 'vendor/'+this.user.id+'/spb?status='+status+'&is_billingable=false&length=1000&length=1000'
      else
        url = 'spb?status='+status+'&is_billingable=false&length=1000'
      this.$http.get(url).then(res=>{
        this.optionSPB = res.data.data
      })
    },
    hideForm(){
      this.$bvModal.hide('modal-select')
      this.spb = null
      this.$refs.simpleRules.reset()
    },
    validationForm(){
      this.$refs.simpleRules.validate().then( success => {
        if(success){
          this.loading = true
          this.$http.post('spb/'+this.spb.id+'/bill')
          .then(()=>{
            this.successSubmit()      
            this.$store.dispatch('sendMessage', '1')
          })
          .catch(err=>{
            this.errorSubmit(err)
          })
          .finally(() => {this.loading = false})
        }else{
          return this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },
    successSubmit(){
      this.$refs.basetable.fetchData()
      this.hideForm()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
    getBillCount(data){
      var count = 0
      var detail = 0
      if(data.billingable.items){
        data.billingable.items.forEach(item => {if(count < item.duration){count += parseInt(item.duration)}})
      }
      if(data.details){
        detail = data.details.length
      }
      return detail+"/"+count+" Bulan"
    },
    getLastMonthBill(data){
      var lastmonth = 0
      if(data.details){
        data.details.forEach(detail =>{
          if(parseInt(lastmonth) < parseInt(detail.year+""+detail.month))
            lastmonth = detail.year+""+detail.month
        })
      }
      return lastmonth?moment(lastmonth).format("MMMM YYYY"):"-"
    },
    getTotalTagihan(data){
      var total_price = 0
      var total_bill = 0
      var duration = 0
      if(data.details){
        data.details.forEach(detail =>{
          total_bill += parseInt(detail.amount)
        })
      }
      if(data.billingable.items){
        data.billingable.items.forEach(item => {
          if(duration < item.duration)
            duration += parseInt(item.duration)

          if(data.billingable.vehicles){
            data.billingable.vehicles.forEach(vehicle => {
              if(vehicle.variant_id == item.variant_id){
                total_price += parseInt(item.price)
              }
            })
          }
        })
      }
      return new Intl.NumberFormat().format(total_bill)+"/"+new Intl.NumberFormat().format(total_price*duration)
    },
    getStatus(data){
      var total_price = 0
      var total_bill = 0
      var duration = 0
      if(data.details){
        data.details.forEach(detail =>{
          total_bill += parseInt(detail.amount)
        })
      }
      if(data.billingable.items){
        data.billingable.items.forEach(item => {
          if(duration < item.duration)
            duration += parseInt(item.duration)

          if(data.billingable.vehicles){
            data.billingable.vehicles.forEach(vehicle => {
              if(vehicle.variant_id == item.variant_id){
                total_price += parseInt(item.price)
              }
            })
          }
        })
      }
      return (total_bill===(total_price*duration))?'lunas':'proses'
    }
  }
}
</script>
<style lang="">

</style>
